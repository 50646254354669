<template>

  <div class="foot">
    <div class="foot1">
      <div class="footLeft">
         <img src="@/assets/login/phone.png" alt="" />
      </div>
      <h1>
        热线电话：
        <p>0731-85685008</p>
      </h1>
      <div class="footBotton">
        <a @click="OpenQQ()"><img src="@/assets/qq.png" /><span>在线咨询</span></a>
      </div>
    </div>
    <!-- <div class="foot2 ">
      <div class="footVx1">
        <img  :class="register == 0 ? '' : ''" />
        <h1></h1>
      </div>
      <div class="footVx2">
        <img  :class="register == 0 ? '' : ''" />
        <h1></h1>
      </div>
    </div> -->
    <div class="foot3">
    
      <h1>湖南中泰智本网络科技有限公司提供技术支持</h1>
      <h1></h1>
    </div>
  </div>

</template>
<script>
export default {
  name: 'Foot',
  props: {
    register: { type: Number, default: 1 },
  },
  data() {
    return {
      
      version: {},
    }
  },
  computed: {},
  created() {
    // 程序版本号
    // this.version = JSON.parse(shell.GetVersion())
  },
  mounted() {},
  watch: {},
  methods: {
    OpenQQ() {
        console.log(window.shell)
        console.log(typeof(window.shell))
      if (window.shell&&typeof(window.shell)=='function') {
        shell.OpenQQ('2141668434')
      } else {
        window.open('https://wpa.qq.com/msgrd?v=3&uin=2141668434&site=qq&menu=yes')
      }
    },
  },
  components: {},
}
</script>

<style scoped lang="less">
.foot {
    position: relative;

  width:12rem;
background: #fff;
  margin: 0 auto 10px;
padding-top: 30px;
  .foot1 {
 display: flex;
    width: 433px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dedede;
    .footLeft {
 
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      margin: 0 17px 0 0;
      border: 1px solid #0735a4;
      border-radius: 50%;
      img {
       width: 17px;
       height: 17px;
      }
    }
    h1 {
  
      margin:0 45px 0 0;
      font-size: 12px;
      color: #999999;
      text-align: left;
      p {
        margin-top: 5px;
        font-size: 20px;
        font-weight: bold;
        color: #333;
      }
    }

    .footBotton {
  
      width: 165px;
      height: 46px;
      margin-top:0;

      color: #0735a4;
      border-radius: 46px;
      border: 1px solid #0735a4;
      a{
  display: flex;
  align-items: center;
justify-content: center;
      }
      img {
        width: 14px;
        height: 18px;
      margin-right: 5px;
       
      }
      span {
       
        font-size: 14px;
        line-height: 46px;
    
        cursor: pointer;
      }
    }
  }
  .foot2 {
    float: right;
    width: 305px;
    height: 180px;
    margin-top: 45px;
    .shadeCode {
      background: #fff;
      opacity: 0.1;
    }
    .footVx1 {
      float: left;
      width: 140px;
      height: 140px;
   
      border-radius: 3px;
      img {
        float: left;
        width: 126px;
        margin: 7px;
      }
      h1 {
        float: left;
        width: 140px;
        margin-top: 15px;
        font-size: 14px;
        text-align: center;
      }
    }
    .footVx2 {
      float: right;
      width: 140px;
      height: 140px;
     
      border-radius: 3px;

      img {
        float: left;
        width: 126px;
        margin: 7px;
      }

      h1 {
        float: left;
        width: 140px;
        margin-top: 15px;
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .foot3 {
  


    h1 {
    margin-top: 10px;
    padding-bottom: 10px;
      font-size: 12px;
      color: #808080;
      a {
        color: #808080;
        text-decoration: none;
      }
    }
  }
}
</style>
