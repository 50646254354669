<template>
  <div class="headdiv">
    <div class="header">
      <div v-if="$route.path=='/login'">
        <img src="@/assets/login/logo.png" alt="" class="logopng">
        <span class="title">天天教育 </span> <span class="shuxian">|</span> <span class="title1"> 第二课堂</span>
      </div>
      <div v-else class="islogin">
        <div> <img src="@/assets/login/logo.png" alt="" class="logopng">
          <span class="title">天天教育 </span> <span class="shuxian"></span> <span class="title1"> 第二课堂</span>
        </div>
        <div v-if="isLogin">
          <span class="username">
            {{userInfo.name}}
          </span>
          <el-dropdown trigger="click" style="height: .4rem;">
            <div class="primarybtn"> {{clazzObj.name |pname}} <i class="el-icon-arrow-down el-icon--right"></i></div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item,index) in queryclazzlist" @click.native="command(item)" :key="index">
                {{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="headlink" @click="gotourl('/home')" v-if="this.$route.path == '/coursecenter'"> 班级课程 </div>
          <div class="headlink" @click="gotourl('/coursecenter')" v-if="this.$route.path == '/home'"> 进入首页 </div>
          <div class="primarybtn" @click="tuichu" v-if="!isShell"> 退出 </div>
          <div class="primary" v-else-if="screenWidth<1450"></div>
        </div>
        <div v-if="!isLogin">
          <div class="primarybtn" @click="gotourl('/login')"> 登录 </div>
        </div>
      </div>

    </div>
    <!--  -->
    <div class="Clist" v-if="isShell">
      <el-dropdown trigger="click" v-if="$route.path!='/login'">
        <i class="iconfont iconGroup69"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="iconfont iconshezhi" @click.native="menuClick('设置')">设置</el-dropdown-item>
          <el-dropdown-item @click.native="menuClick('查看更新')" icon="iconfont iconjianchagengxin">查看更新</el-dropdown-item>
          <el-dropdown-item @click.native="menuClick('关于我们')" icon="iconfont iconguanyuwomen">关于我们</el-dropdown-item>
          <el-dropdown-item v-if="isLogin" @click.native="menuClick('退出')" icon="iconfont icontuichu">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <i class="iconfont iconGroup70" @click="AppMinimize()"></i>
      <i class="iconfont iconGroup71" @click="ApptoggleFullscreen()"></i>
      <i class="iconfont iconGroup72" @click="AppClose()"></i>

    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isShell: false,
        screenWidth: document.body.clientWidth,
        isLogin:true
      }
    },
    computed: {
      // 用户信息
      userInfo() {

        return this.$store.state.userInfo
      },
      // 班级信息
      clazzObj() {
        return this.$store.state.clazzObj
      },
      // 班级集合
      queryclazzlist() {
        return this.$store.state.queryclazzlist
      }
    },
    activated() {
      this.isLogin=localStorage.getItem('token')?true:false 
      if (this.$route.path == '/home'||this.$route.path == '/coursecenter'||this.$route.path == '/study') {
        this.getPuserInfo()
        //this.$emit('querycourse', this.clazzObj)
       // console.log('activated');
      }
    },

    created() {
        //this.isLogin=localStorage.getItem('token')?true:false 
        //if(this.isLogin){
           //this.getPuserInfo()
          // this.$emit('querycourse', this.clazzObj)
        //}
    },
    filters: {
      pname: function (r) {
        if (r == undefined) return '暂无班级'
        return r.length > 10 ? r.substring(0, 10) + '..' : r;
      }
    },
    mounted() {

      if (window.shell) {
        this.isShell = true
      }
    },
    methods: {
      menuClick(type) {
        if (type == '退出') {
          return this.tuichu()
        }
        shell.OpenForm(type)
      },
      // 最小化窗口
      AppMinimize() {
        shell.Minimize();

      },
      ApptoggleFullscreen() {

        shell.Maximize();
      },
      // 关闭窗口
      AppClose(e) {
        shell.Close();
      },
      // 切换班级
      command(e) {
        //没切换不处理
        if (this.$store.state.clazzObj.id != e.id) {
          this.$store.commit('clazzObj', e)
          localStorage.setItem('clazzObj', JSON.stringify(e))
          this.$emit('querycourse', e)
          console.log('切换班级')
          if (this.$route.name == "study") {
            this.$router.push({
              path: '/'
            })
            location.reload()
          }
        }
      },
      // 获取用户信息
      getPuserInfo() {
        this.axiosGetOld('/api/yyb/v1/user/api/getPuserInfo?', {
          timestamp: new Date().getTime()
        }, (res => {
          if (res.code == 0) {
            this.$store.commit('userInfo', res.obj)
            this.queryclazz(res.obj)
          } else {
            this.$message.error(res.msg);
          }
        }))
      },
      // 获取班级信息
      queryclazz(arr) {

        this.axiosGet('/afterclass-api/api/yyb/v1/after/api/queryclazzlist', {
          uid: arr.uid
        }, (res => {
          if (res.code == 0) {
            this.$store.commit('queryclazzlist', res.obj)
            if(res.obj.length == 0)
            {
              this.$store.commit('clazzObj', {})
              localStorage.setItem('clazzObj', '{}')
            }
            else if (localStorage.getItem('clazzObj')) 
            {
              this.$store.commit('clazzObj', JSON.parse(localStorage.getItem('clazzObj')))
            } 
            else 
            {
              this.$store.commit('clazzObj',  res.obj[0])
              localStorage.setItem('clazzObj', JSON.stringify(res.obj[0]))
            }
            this.$emit('querycourse', this.clazzObj)
          }

        }))
      },

      tuichu() {
        this.$confirm('确认要退出吗？', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',

        }).then(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('studyinfo');
          localStorage.removeItem('clazzObj');
          this.$store.commit('userInfo', {})
          this.$store.commit('clazzObj', {})
          this.$store.commit('queryclazzlist', [])
          if (this.$route.name == "study") {
            this.$router.push({
              path: '/'
            })
          }
          else{
            window.location.reload();
          }
          
        }).catch(() => {

        });

      },
      gotourl(name){
        this.$router.push(name);
      }
    }
  }
</script>
<style lang="less" scoped>
  .username {
    color: #909399;
    font-size: 14px;

  }

  .Clist {
    max-width: 300px;
    z-index: 999999;
    right: 30px;
    position: absolute;
    top: 0.20rem;
    align-items: center;

    display: flex;
    justify-content: flex-end;

    .iconfont {
      font-size: 22px;
      margin: 0 10px;
      cursor: pointer;
      padding: .05rem;
    }

    .iconfont:hover {
      background: #0735A4;
      border-radius: 50%;
      color: #fff;
    }

  }

  .headdiv {
    position: fixed;
    top: 0px;
    right: 0;
    width: 100%;
    z-index: 1993;
    height: 0.8rem;
  }

  .header {
    display: flex;
    align-items: center;
    height: .8rem;
    width: 100%;
    line-height: .8rem;
    background: #fff;

    .logopng {
      width: .48rem;
      height: .48rem;
    }

    >.islogin {
      justify-content: space-between;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      div {
        display: flex;
        align-items: center;
      }
    }

    >div {
      display: flex;
      align-items: center;


      width: 12rem;
      margin: auto;

      .title {
        margin-left: 10px;
        font-size: .26rem;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        color: #0e1432;
      }

      .title1 {
        font-size: .24rem;
        color: #0e1432;
        font-weight: 400;
      }
    }

    .shuxian {
      display: block;
      margin: 0 .1rem;
      width: 0.01rem;
      background: #ccc;
      height: .3rem;
      overflow: hidden;
    }

    .el-dropdown-link {
      color: #0735a4;
      cursor: pointer;
      margin-right: .2rem;
    }

    .primary {
      padding: 0 .2rem;
      height: .3rem;
      width: 0.9rem;
    }

    .primarybtn {
      margin-left: .2rem;
      padding: 0 .2rem;
      height: .3rem;
      line-height: .3rem;
      background: #e6ebf6;
      border-radius: .28rem;
      font-size: 12px;
      text-align: center;
      color: #0735a4;
      cursor: pointer;
    }
    .headlink{
      height: .26rem;
      opacity: 1;
      background: #ffffff;
      border: 1px solid #0735a4;
      border-radius: 4px;
      margin-left: 0.2rem;
      padding: 0 0.2rem;
      line-height: 0.3rem;
      font-size: 12px;
      text-align: center;
      color: #0735a4;
      cursor: pointer;
    }
  }
</style>